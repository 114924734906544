import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path2/src/components/DefaultLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`December, 2020`}</h1>
    <h2><a parentName="h2" {...{
        "href": "https://patrickreviews808472008.wordpress.com/2020/12/22/little-martian-an-alien-adventure-of-crafting-and-survival/"
      }}>{`Review by "Patrick Reviews"`}</a></h2>
    <p>{`An positive first-play video and review. Patrick spends around half an hour testing out Little Martian Alpha.1.`}</p>
    <blockquote className="twitter-tweet" data-dnt="true" data-theme="dark"><p lang="en" dir="ltr">I stumbled upon an awesome little space survival game: Little Martian by <a href="https://twitter.com/MartiansGame?ref_src=twsrc%5Etfw">@MartiansGame</a>.<br /><br />I did a review here, accompanied by a bit of a video playthrough:<a href="https://t.co/L6W2oM2MjR">https://t.co/L6W2oM2MjR</a><a href="https://twitter.com/hashtag/gamedev?src=hash&amp;ref_src=twsrc%5Etfw">#gamedev</a> <a href="https://twitter.com/hashtag/indiegame?src=hash&amp;ref_src=twsrc%5Etfw">#indiegame</a> <a href="https://twitter.com/hashtag/indiegamedev?src=hash&amp;ref_src=twsrc%5Etfw">#indiegamedev</a> <a href="https://twitter.com/hashtag/pixelart?src=hash&amp;ref_src=twsrc%5Etfw">#pixelart</a></p>&mdash; P. Edward (@PatVanMack) <a href="https://twitter.com/PatVanMack/status/1341500379796824065?ref_src=twsrc%5Etfw">December 22, 2020</a></blockquote>
    <h2><a parentName="h2" {...{
        "href": "https://powerspikegames.com/2020/12/19/chat-with-little-martian-developers/"
      }}>{`Interview with "Power Spike Games"`}</a></h2>
    <p>{`We were interviewed by Adam the week before, about the ideas and inspiration behind the game, and how we got started.`}</p>
    <blockquote className="twitter-tweet" data-dnt="true" data-theme="dark"><p lang="en" dir="ltr">Take a sneak peek at an up-and-coming sci-if survival game by <a href="https://twitter.com/MartiansGame?ref_src=twsrc%5Etfw">@MartiansGame</a> and <a href="https://twitter.com/themajorbruno?ref_src=twsrc%5Etfw">@themajorbruno</a>! Demo out now.<a href="https://twitter.com/hashtag/indie?src=hash&amp;ref_src=twsrc%5Etfw">#indie</a> <a href="https://twitter.com/hashtag/indiegame?src=hash&amp;ref_src=twsrc%5Etfw">#indiegame</a> <a href="https://twitter.com/hashtag/indiedeveloper?src=hash&amp;ref_src=twsrc%5Etfw">#indiedeveloper</a> <a href="https://twitter.com/hashtag/gaming?src=hash&amp;ref_src=twsrc%5Etfw">#gaming</a> <a href="https://twitter.com/hashtag/gamingnews?src=hash&amp;ref_src=twsrc%5Etfw">#gamingnews</a> <a href="https://twitter.com/hashtag/scifi?src=hash&amp;ref_src=twsrc%5Etfw">#scifi</a> <a href="https://twitter.com/hashtag/survivalgame?src=hash&amp;ref_src=twsrc%5Etfw">#survivalgame</a> <a href="https://twitter.com/hashtag/minecraft?src=hash&amp;ref_src=twsrc%5Etfw">#minecraft</a><a href="https://t.co/UnKOWTfQc4">https://t.co/UnKOWTfQc4</a></p>&mdash; PowerSpikeGames (@PowerSpikeGames) <a href="https://twitter.com/PowerSpikeGames/status/1340620573009981441?ref_src=twsrc%5Etfw">December 20, 2020</a></blockquote>
    <h1>{`August, 2020`}</h1>
    <h2><a parentName="h2" {...{
        "href": "https://powerspikegames.com/2020/08/27/games-to-look-out-for-little-martian/"
      }}>{`Preview by "Power Spike Games"`}</a></h2>
    <p>{`Scott approached us for some insight into our plans for the game, for a preview article.`}</p>
    <blockquote className="twitter-tweet" data-dnt="true" data-theme="dark"><p lang="en" dir="ltr">Another <a href="https://twitter.com/hashtag/indiegame?src=hash&amp;ref_src=twsrc%5Etfw">#indiegame</a> article up now for <a href="https://twitter.com/MartiansGame?ref_src=twsrc%5Etfw">@MartiansGame</a> very excited to learn more about this game as it’s development progresses <a href="https://t.co/u2QhiZ6zjU">https://t.co/u2QhiZ6zjU</a></p>&mdash; PowerSpikeGames (@PowerSpikeGames) <a href="https://twitter.com/PowerSpikeGames/status/1299049518512705539?ref_src=twsrc%5Etfw">August 27, 2020</a></blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      